<template>
  <div :class="{container:true,notScroll:isScroll}">
       <!-- 顶部 -->
    <MoTitle :showLeft="false" title="岗位目标动态监测" @moTitltFn="navPanel"></MoTitle>
    <div class="moSchoolContainer">
      <!-- 头部 -->
      <div class="moTitle center">
          <h1 style="color:#fff;font-size: 1.5rem;margin-bottom:20px">岗位目标动态监测</h1>
          <button class="showBtn" @click="$router.push('/moShow')">立即预约演示</button>
      </div>
      <div class="contentBgColor center">
        <h2 class="h2Desc">应用框架</h2>
        <span class="spanDesc"></span>
        <img src="@/assets/solute/smartXycg/gnt.jpg" alt="" style="width:100%;margin:30px 0">
      </div>

      <div class="contentBgColor center">
        <h2 class="h2Desc">业务痛点</h2>
        <span class="spanDesc"></span>
        <div class="moValueContentImg">
          <div v-for="(item,index) in valueList" :key="index" class="moValueItemImg">
            <img :src="item.src" alt="" class="moVlaueImg">
            <div class="moValueTextImg">{{item.content}}</div>
          </div>
        </div>
      </div>

      <div class="contentBgColor center">
        <h2 class="h2Desc">解决方案</h2>
        <span class="spanDesc"></span>
        <div class="moValueContent">
          <div v-for="(item,index) in soliteData" :key="index" class="moValueItem">
            <div class="moValueTitle">{{item.title}}</div>
            <div class="moValueLine"></div>
            <div class="moValueText">{{item.content}}</div>
          </div>
        </div>
      </div>

      <div class="contentBgColor center">
          <h2 class="h2Desc">应用案例</h2>
          <span class="spanDesc"></span>
          <div class="moCase">
            <MoSwiper :list="caseData"></MoSwiper>
          </div>
      </div>
    </div>
    <MoBottom></MoBottom>
      <MoFoot></MoFoot>
  </div>
</template>

<script>
import MoTitle from '@/components/Motitle'
import MoSwiper from '@/components/moswiper'
export default {
  name: 'moSmartXyyjcg',
  components: {
    MoTitle,
    MoSwiper
  },
  data () {
    return {
      isScroll: false,
      valueList: [
        { content: '学院绩效考核工作业务复杂、事务繁多，每个学院考核指标、流程及要求不同，统计工作量大。', src: require('@/assets/solute/SmartCampus/smartOne.png'), hoverScr: require('@/assets/oneHover.png') },
        { content: '教师考核选聘把关不严，考核评价缺乏整体设计，考核结果的科学运用有待完善。', src: require('@/assets/solute/SmartCampus/smartTwo.png'), hoverScr: require('@/assets/towHover.png') },
        { content: '目标监控与考核测评脱钩，绩效体系搭建与岗位目标考核设定不匹配。', src: require('@/assets/solute/SmartCampus/smartThree.png'), hoverScr: require('@/assets/threeHover.png') },
        { content: '数据统计和结果分析困难，人工核算容易出现失误。', src: require('@/assets/solute/SmartCampus/smartFour.png'), hoverScr: require('@/assets/fiveHover.png') }
      ],
      soliteData: [
        { title: '聚焦岗位职责，精细化过程管理', content: '灵活设定岗位职责目标，岗位指标可从指标模板库选择，目标精准管理，确保高效执行落地，让考核有理有据。' },
        { title: '绩效数据智能采集，全流程自动监控', content: '绩效数据自动汇总，业绩指标智能判断，考核流程自动化，业绩材料电子化，保障评价打分结果客观、立体、全面。' },
        { title: '激发教师潜能，提升组织效能', content: '指标拆解细分任务，明确目标计划，高效执行落地，学院绩效与个人绩效关联，牵引教师聚焦学院目标达成。' },
        { title: '构建全景绩效数据看板，敏捷响应学院动态分析需求', content: '基于全校各学院绩效达成分析，预置目标达成、组织效能、人才盘点三大维度分析主题和常用分析指标，赋能决策。' }

      ],
      caseData: [
        { src: require('@/assets/TypicalCase/typeCase/xadzkj.png'), title: '西安电子科技大学', p: '西安电子科技大学院面向学院的全周期业绩成果测算系统于 2021 年开始建设，基于“大平台+子模块+个性化应用”的整体规划、分期建设、逐步实施，持续上线优化的建设策略，目前已经完成了平台整体框架的建设，能面向不同学院建设考核模块，实现个性化指标搭建、申报审核页面及审核流程定义，能同步相关职能部门各业务系统绩效数据，实现定性考核与定量考核相结合的绩效考核业务全流程化管理。业绩成果测算系统，实现了各学院业绩量化核算和绩效分配，提升了不同学院绩效考核业务管理的效率和服务水平。面向学院的全周期业绩成果测算系统，助力西安电子科技大学实现目标过程管理及考核结果管理一体化。' }
      ]
    }
  },
  methods: {
    navPanel (v) {
      this.isScroll = v
    },
    getHeight () {
      if (window.innerWidth > 800) {
        this.$router.push('/solution/smartXyyjcg')
      }
    }
  },
  created () {
    // 监听可视窗口宽度
    window.addEventListener('resize', this.getHeight)
    this.getHeight()
  },
  destroyed () {
    window.removeEventListener('resize', this.getHeight)
  }
}
</script>

<style scoped>
* {

  margin: 0;
  padding: 0;
  font-size: 16px;
  color: #222;

}
img {
   object-fit: cover;
}
.container{
  width: 100%;
}
.notScroll{
  height: 0rem;
  overflow: hidden;
}
.moreBtn{
  width: 6rem;
  height: 2rem;
  border-radius: 1.875rem;
  border: 1px solid rgba(211, 218, 228, 0.5);
  line-height: 2rem;
  color: #444444;
  font-size: .875rem;
  background-color: #fff;
}
.h2Desc{
  font-size: 1.375rem;
  color: #222;
  margin-top: 2.5rem;
}
.spanDesc{
  display: inline-block;
  background-color: #D70C0C;
  width: 1.875rem;
  height: .25rem;
  border-radius: .1875rem;
  margin-top: 1.25rem;
}
.center {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.showBtn{
  width: 8.75rem;
  height: 2.5rem;
  background: linear-gradient(135deg, #FF8484 0%, #C61F1F 100%);
  border-radius: 2.125rem;
  border: 0;
  line-height: 2.5rem;
  font-size: .875rem;
  color: #fff;
}
.contentBgColor{
  width: 100%;
    padding: 0 1.25rem;
box-sizing: border-box;
  background: linear-gradient(180deg, #FFFFFF 0%, #F7F5F4 100%);
}

.moTitle{
  position: relative;
  width: 100%;
  height: 15rem;
  background: url("~@/assets/solute/smartXycg/banner.png") center no-repeat;
  padding: 0 2.5rem;
  background-size: cover;
  object-fit: cover;

  color: #fff;
  text-align: center;
    display: flex;
  flex-direction: column;
  justify-content: center;
}
.moSchoolContainer{
  padding-top: 2.75rem;
}
.moCase{
  width: 100%;
  margin-top: 1.875rem;
}

.designIdea{
  margin: 20px 0;
  font-size: .9375rem;
line-height: 1.5rem;

}
.knowMoreBtn{
  width: 120px;
  height: 36px;
  background: #FFFFFF;
  border-radius: 30px;
  border: 1px solid rgba(211,218,228,0.5);
  color: #444444;
  font-size: 14px;
}

.moValueTopText{
  text-align:center;
  margin-top:15px;
  font-size: 14px;
  font-weight: 400;
  color: #444444;
  line-height: 22px;
}
.moValueContent{
  width: 100%;
  padding: 20px 0;
  box-sizing: border-box;
}
.moValueItem{
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  background: #FFFFFF;
  border-radius: 8px;
  margin-bottom: 20px;
  border: 1px solid rgba(220,223,230,.5);
  box-sizing: border-box;
}
.moValueTitle{
  font-size: 16px;
  font-weight: 600;
  color: #222222;
  line-height: 24px;
}
.moValueLine{
  width: 20px;
  height: 3px;
  background: #D70C0C;
  margin: 8px 0;
}
.moValueText{
  font-size: 14px;
  font-weight: 400;
  color: #444444;
  line-height: 22px;
}

.moValueContentImg{
  width: 100%;
  padding: 20px 0;
  box-sizing: border-box;
}
.moValueItemImg{
  display: flex;
  align-items: center;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  background: #FFFFFF;
  border-radius: 8px;
  margin-bottom: 20px;
  border: 1px solid rgba(220,223,230,.5);
  box-sizing: border-box;
}
.moVlaueImg{
  width: 40px;
  height: 40px;
  object-fit: cover;
}
.moValueTextImg{
  font-size: 16px;
  font-weight: 400;
  color: #222222;
  line-height: 24px;
  margin-left: 20px;
}
</style>
